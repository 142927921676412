<template>
  <div
    class="container py-2"
    style="max-width:980px"
  >
    <b-img
      fluid
      :src="require('@/assets/images/logo/logo_leadjet_grey.png')"
      class="pricing-trial-img"
      alt="svg img"
      style="width:120px"
    />

    <b-card
      style="padding: 30px 90px"
      class="mt-2"
    >
      <router-view />
    </b-card>

    <b-card
      class="shadow-none py-1 faq-contact-card text-center"
    >
      <b-avatar
        size="42"
        rounded
        variant="light-primary"
        class="mb-2"
      >
        <feather-icon
          icon="MailIcon"
          size="18"
        />
      </b-avatar>
      <h4>hello@surfe.com</h4>
      <span class="text-body">Get in touch with us</span>
    </b-card>
  </div>
</template>

<script>
import { BAvatar, BCard, BImg } from 'bootstrap-vue'

export default {
  components: { BAvatar, BCard, BImg },
}
</script>

<style scoped>
p {
  font-size: 16px;
}

.faq-contact-card {
  background-color: #f1f1f2;
}
</style>
